.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.fill-window {
    height: calc(90vh - 8px);
    position: absolute;
    left: 0;
    width: 100%;
    font-family: "Helvetica";
}

h1 {
    font-size: 30px;
    margin-left: 15px;
}

h2 {
    font-size: 20px;
    font-weight: bold;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Make scrollbar invisible */


/* html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}



::-webkit-scrollbar-thumb {
    background: #FF0000;
} */


/* Fixed that input date was not aligned to the left */

input[type=date] {
    display: block !important;
    text-align: left !important;
}

.color-white {
    color: white;
}

.color-black {
    color: black !important;
}

.color-gold {
    color: goldenrod !important;
}