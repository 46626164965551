.buttons-footer {
    border-radius: 0;
}

.background-color-blue {
    background-color: #2F5FA1 !important;
    color: white;
}

.inputs-fixed-height {
    width: 100%;
    height: 35px !important;
}

.inputs-full-height {
    width: 100%;
    height: 100% !important;
}

input[type="date"]::-webkit-date-and-time-value {
    text-align: left !important;
} 

input[type="date"] {
    text-align: left !important;
}