.table-column-width {
    width: 150px !important;
}

.timetable-table {
    border-radius: 5px;
    background-color: rgb(233, 232, 232);
}

.row-border-top {
    border-top: 1px solid white;
}

.label-padding {
    margin-top: 5px;
    margin-bottom: 5px;
}