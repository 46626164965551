.detail-button {
    width: 100%;
    background-color: rgb(226, 224, 224) !important;
    color: #2F5FA1 !important;
    border-color: rgb(226, 224, 224) !important;
    border-top-color: white !important;
    padding-bottom: 0;
    padding-top: 0;
}

.asset {
    border-radius: 4px;
    background-color: rgb(226, 224, 224);
}